// this file might be a symlink
// magic values will be replaced during docker container runtime

module.exports = {
    NEXT_SHOP_EXTERNAL_URL: 'https://wapro-365.pl',
    NEXT_SHOP_EXTERNAL_DOMAIN: 'wapro-365.pl',
    BACKEND_INTERNAL_URL: 'http://api.wapro-365.pl:8080',
    BACKEND_EXTERNAL_URL: 'https://panel.wapro-365.pl/api',
    TINYMCE_API_KEY: 'qil8aezplun7xg2k5si0o8uuzlb9czbkgw54uyapf5hi1dfl'
}
